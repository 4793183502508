import { isGroupTypeValue } from '../utils/experiments';
import { checkExperimentOverride } from '../utils/checkExperimentOverride';
import { laboratoryClient, logExposure } from '../utils/experiments';
import { useEffect } from 'react';
import { useState } from 'react';
import { useTier } from './useTier';
export const useIsGPRO0003 = (variant = '') => {
  const override = checkExperimentOverride('gpro-0003');
  const {
    tier,
    loading,
    error
  } = useTier();
  if (override === 'control') {
    return {
      variant: override,
      loading: false,
      isGPRO0003: false,
      isGPRO0003Audience: false,
      error: undefined
    };
  }
  if (override === 'variation') {
    return {
      variant: override,
      loading: false,
      isGPRO0003: true,
      isGPRO0003Audience: true,
      error: undefined
    };
  }
  if (!['pro', 'starter'].includes(tier) && !loading) {
    return {
      variant: 'control',
      loading: false,
      isGPRO0003: false,
      isGPRO0003Audience: false,
      error: undefined
    };
  }
  if (loading) {
    return {
      variant: undefined,
      loading: true,
      isGPRO0003: undefined,
      error: undefined,
      isGPRO0003Audience: undefined
    };
  }
  if (error || !isGroupTypeValue(variant, 'gpro-0003')) {
    return {
      variant: undefined,
      loading: false,
      isGPRO0003: undefined,
      error: error || new Error(`Invalid variant ${variant}`),
      isGPRO0003Audience: undefined
    };
  }
  const isVariation = variant === 'variation';
  return {
    variant,
    loading: false,
    isGPRO0003: isVariation,
    isGPRO0003Audience: true,
    error: undefined
  };
};
export const useGPRO0003WithExposureLogging = () => {
  const [experimentGroup, setExperimentGroup] = useState(null);
  useEffect(() => {
    laboratoryClient.resolve(treatments => {
      var _treatments$gpro;
      setExperimentGroup((_treatments$gpro = treatments['gpro-0003']) === null || _treatments$gpro === void 0 ? void 0 : _treatments$gpro.parameters.group);
    });
  }, []);
  const isGPRO0003 = useIsGPRO0003(experimentGroup);
  useEffect(() => {
    if (isGPRO0003.isGPRO0003Audience) {
      logExposure('gpro-0003');
    }
  }, [isGPRO0003.isGPRO0003Audience]);
  return Object.assign({}, isGPRO0003);
};