import { useQuery } from 'data-fetching-client';
import { SET_SHOW_SEQUENCE_COACHING_TIP } from '../dataFetchingClient/queries/sequenceCoachingTooltip';
export const useSequenceCoachingTip = () => {
  const {
    data
  } = useQuery(SET_SHOW_SEQUENCE_COACHING_TIP);
  if (data && data.sequenceCoachingTooltip) {
    return {
      show: data.sequenceCoachingTooltip === 'true'
    };
  }
  return {
    show: false
  };
};