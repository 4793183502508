module.exports = {
  "gpro-0003": {
    "identifierType": "PORTAL_ID",
    "parameters": {
      "group": [
        "control",
        "variation"
      ]
    }
  },
  "tacq-0002": {
    "identifierType": "PORTAL_ID",
    "maxAge": 86400000,
    "parameters": {
      "group": [
        "control",
        "variation"
      ]
    }
  }
};