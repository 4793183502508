const availableTrialsAdapter = availableTrials => {
  return availableTrials.map(trial => {
    var _trial$parcels;
    const firstTrialParcel = (_trial$parcels = trial.parcels) !== null && _trial$parcels !== void 0 && _trial$parcels.length ? trial.parcels[0] : null;
    return {
      name: trial.name,
      trialId: trial.id,
      apiName: firstTrialParcel && firstTrialParcel.productApiName || null
    };
  });
};
export default availableTrialsAdapter;