import { useGPRO0003WithExposureLogging } from 'growth-pro-utils-lib/hooks/useIsGPRO0003';
const ExperimentExposures = () => {
  // EXAMPLE BELOW 👇
  // const isEligibleForOnbc007 = useIsEligibleForOnbc007();

  // useEffect(() => {
  //   if (isEligibleForOnbc007) {
  //     logExposure(ONBC007_TREATMENT_KEY);
  //   }
  // }, [isEligibleForOnbc007]);

  useGPRO0003WithExposureLogging();
  return null;
};
export default ExperimentExposures;