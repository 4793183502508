import { useState, useCallback, useMemo } from 'react';
import { useControlledState } from '../../hooks/useControlledState';

/**
 * Manages the selected values in the Select component.
 */
export const useControlledSelectedValues = ({
  controlledSelectedValues,
  onSelectedOptionChange,
  multi = false
}) => {
  const {
    value: selectedValues,
    setValue: setSelectedValues
  } = useControlledState(controlledSelectedValues, []);
  const handleSelect = useCallback((text, value) => {
    let newSelectedValues;
    if (value === null) {
      newSelectedValues = [];
    } else if (multi) {
      if (selectedValues.includes(value)) {
        newSelectedValues = selectedValues.filter(v => v !== value);
      } else {
        newSelectedValues = [...selectedValues, value];
      }
    } else {
      newSelectedValues = [value];
    }

    // Call `setSelectedValues` for uncontrolled state
    setSelectedValues(newSelectedValues);

    // Always call `onSelectedOptionChange` regardless of controlled state
    onSelectedOptionChange === null || onSelectedOptionChange === void 0 || onSelectedOptionChange({
      text,
      value,
      selectedValues: newSelectedValues
    });
  }, [selectedValues, setSelectedValues, onSelectedOptionChange, multi]);
  return {
    selectedValues,
    handleSelect
  };
};

/**
 * Manages the input value in the searchable Select component.
 */
export const useControlledInputValue = ({
  controlledInputValue,
  onInputValueChange
}) => {
  const {
    value: inputValue,
    setValue: setInputValue
  } = useControlledState(controlledInputValue, '');
  const handleInputValueChange = useCallback(newValue => {
    setInputValue(newValue);
    onInputValueChange === null || onInputValueChange === void 0 || onInputValueChange(newValue);
  }, [setInputValue, onInputValueChange]);
  return {
    inputValue,
    handleInputValueChange
  };
};
export const useAsyncOptions = ({
  loadAsyncOptions
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const [error, setError] = useState(null);
  const fetchOptions = useCallback(input => {
    if (!loadAsyncOptions) {
      return;
    }
    setIsLoading(true);
    setError(null);
    loadAsyncOptions({
      input,
      callback: result => {
        setIsLoading(false);
        if (result.error) {
          setError(result.error);
        } else {
          setOptions(result.options);
        }
      }
    });
  }, [loadAsyncOptions]);
  return {
    isLoading,
    error,
    options,
    fetchOptions
  };
};

/**
 * Outputs the reference text and layout configuration.
 *
 * Note that the reference layout output is only concerned with the
 * `referenceIndicator` and `selectedItemPlaceholder` props, not the down carat
 * and remove selections buttons that may also be rendered in the reference
 * element.
 */
export const useReferenceContent = ({
  options,
  selectedValues,
  selectedItemPlaceholder,
  multi,
  referenceIndicator,
  prefixWithSelectedItemsPlaceholder
}) => {
  const referenceText = useMemo(() => {
    if (selectedValues.length === 0) {
      return selectedItemPlaceholder || null;
    }
    if (multi) {
      return selectedItemPlaceholder ? `(${selectedValues.length}) ${selectedItemPlaceholder}` : null;
    } else {
      const selectedOption = options.filter(option => selectedValues.includes(option.value))[0];
      if (!selectedOption) {
        return selectedItemPlaceholder || null;
      }
      return prefixWithSelectedItemsPlaceholder && selectedItemPlaceholder ? `${selectedItemPlaceholder}: ${selectedOption.text}` : selectedOption.text;
    }
  }, [selectedValues, options, multi, selectedItemPlaceholder, prefixWithSelectedItemsPlaceholder]);
  const getReferenceLayout = () => {
    if (referenceIndicator && selectedItemPlaceholder) {
      return 'icon-text';
    } else {
      return 'text-only';
    }
  };
  return {
    referenceText,
    referenceLayout: getReferenceLayout(),
    referenceIndicator: referenceIndicator !== null && referenceIndicator !== void 0 ? referenceIndicator : null
  };
};