import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
const _excluded = ["userInfo"];
import { useUserInfo } from './useUserInfo';
var Targeting;
(function (Targeting) {
  Targeting["ENTERPRISE"] = "zorse-chatflow-targeting-enterprise";
  Targeting["PRO"] = "zorse-chatflow-targeting-pro";
  Targeting["STARTER"] = "zorse-chatflow-targeting-starter";
  Targeting["LEGACY_MARKETING_BASIC"] = "zorse-webticket";
})(Targeting || (Targeting = {}));
export const getProductTier = scopes => {
  if (scopes.includes(Targeting.ENTERPRISE)) {
    return 'enterprise';
  }
  if (scopes.includes(Targeting.PRO)) {
    return 'pro';
  }
  if (scopes.includes(Targeting.STARTER)) {
    return 'starter';
  }
  if (scopes.includes(Targeting.LEGACY_MARKETING_BASIC)) {
    return 'starter';
  }
  return 'free';
};
export const useTier = () => {
  var _userInfo$user;
  const _useUserInfo = useUserInfo(),
    {
      userInfo
    } = _useUserInfo,
    rest = _objectWithoutPropertiesLoose(_useUserInfo, _excluded);
  const tier = getProductTier((userInfo === null || userInfo === void 0 || (_userInfo$user = userInfo.user) === null || _userInfo$user === void 0 ? void 0 : _userInfo$user.scopes) || []);
  return Object.assign({
    tier,
    userInfo
  }, rest);
};