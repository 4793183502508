// Polyfill for el.matches(selector)
export const matches = (el, selector) => {
  if (!el) {
    return false;
  }
  if (el.matches) {
    return el.matches(selector);
  }
  //@ts-expect-error migrated from UIComponents
  if (el.msMatchesSelector) {
    //@ts-expect-error migrated from UIComponents
    return el.msMatchesSelector(selector);
  }
  if (el.parentElement) {
    const matchingSiblings = el.parentElement.querySelectorAll(selector);
    for (let i = 0; i < matchingSiblings.length; i++) {
      if (matchingSiblings[i] === el) {
        return true;
      }
    }
  }
  return false;
};
export const FOCUSABLE = `
  a[href]:not([tabindex="-1"]),
  button:enabled:not([tabindex="-1"]),
  input:enabled:not([tabindex="-1"]),
  textarea:enabled:not([tabindex="-1"]),
  select:enabled:not([tabindex="-1"]),
  [tabindex]:not(:disabled):not([tabindex="-1"]),
  [contenteditable]:not(:disabled):not([tabindex="-1"])
`;