import { useState, useEffect } from 'react';
import userInfo from 'hub-http/userInfo';
export const useUserInfo = () => {
  const [userInfoState, setUserInfoState] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  useEffect(() => {
    userInfo().then(data => {
      setUserInfoState(data);
    }).catch(err => {
      setError(err);
    }).finally(() => {
      setLoading(false);
    });
  }, []);
  return {
    userInfo: userInfoState,
    loading,
    error
  };
};
export const useScopes = () => {
  var _info$user;
  const {
    userInfo: info
  } = useUserInfo();
  return (info === null || info === void 0 || (_info$user = info.user) === null || _info$user === void 0 ? void 0 : _info$user.scopes) || [];
};
export const useHasScope = scope => {
  const scopes = useScopes();
  return scopes.includes(scope);
};
export const useIsSuperAdmin = () => {
  return useHasScope('super-admin');
};