export const getIconColor = use => {
  switch (use) {
    case 'danger':
      return 'fill-alert-default';
    case 'primary':
    case 'secondary':
      return 'fill-brand-default';
    case 'secondary-ghost':
      return 'fill-surface-default';
    case 'primary-white':
    case 'tertiary':
    case 'tertiary-light':
    case 'tertiary-extra-light':
      return 'fill-accent-neutral-default';
    case 'success':
      return 'fill-positive-default';
    case 'link':
    default:
      return 'fill-info-default';
  }
};
export const getIconSize = size => {
  switch (size) {
    case 'xs':
    case 'extra-small':
      return '500';
    case 'md':
    case 'medium':
      return '800';
    case 'sm':
    case 'small':
    default:
      return '700';
  }
};
export const getInnerFlexProps = iconSizeValue => {
  return {
    alignItems: 'center',
    justifyContent: 'center',
    blockSize: iconSizeValue,
    inlineSize: iconSizeValue
  };
};
export const getRingColor = (theme, use) => {
  switch (use) {
    case 'on-dark':
      return 'rgba(255,255,255,0.5)';
    case 'danger':
      return theme.color['fill-accent-red-hover'];
    case 'primary':
    case 'secondary':
      return theme.color['fill-accent-orange-hover'];
    case 'secondary-ghost':
      return 'rgba(0, 0, 0, 0)';
    case 'primary-white':
    case 'tertiary':
    case 'tertiary-light':
    case 'tertiary-extra-light':
      return theme.color['text-core-disabled'];
    case 'success':
      return theme.color['fill-positive-hover'];
    case 'link':
    default:
      return theme.color['fill-secondary-hover'];
  }
};
export const getIndicatorColor = use => {
  switch (use) {
    case 'danger':
      return 'fill-alert-default';
    case 'primary':
    case 'secondary':
      return 'fill-primary-default';
    case 'primary-white':
    case 'tertiary':
    case 'tertiary-light':
    case 'tertiary-extra-light':
      return 'fill-accent-neutral-default';
    case 'success':
      return 'fill-positive-default';
    case 'on-dark':
    case 'secondary-ghost':
      return 'fill-surface-default';
    case 'link':
    default:
      return 'fill-info-default';
  }
};