import { useTheme } from 'styled-components';
import { BREAKPOINTS } from '../Grid/Grid.types';
import { rgba } from '../../utils/color';
import { useIconSize } from 'foundations-assets';
const gtSmallMediaQuery = `@media only screen and (min-width: ${BREAKPOINTS.sm}px)`;
const smallMediaQuery = `@media only screen and (max-width: ${BREAKPOINTS.sm}px)`;

// The type for background and border colors was broadened in
// `ButtonStateStyles` to `Color | RGBAColorToken | (string & {})` to accommodate
// setting a rgba and transparent, non-token values for the ghost and transparent
// button variants. In the future, this value may come from a token
// and we can remove the variables and type casts below.
const toColor = (theme, buttonStateColor) => {
  if (typeof buttonStateColor === 'object') {
    return rgba(theme.color[buttonStateColor.color], buttonStateColor.alpha);
  }
  return theme.color[buttonStateColor] || buttonStateColor;
};
export const useUseVariantCssObject = (isLinkUse, variantTokens) => {
  const theme = useTheme();
  return Object.assign({
    display: 'inline-block',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    backgroundColor: toColor(theme, variantTokens.idle.backgroundColor),
    borderColor: toColor(theme, variantTokens.idle.borderColor),
    color: toColor(theme, variantTokens.idle.color),
    textDecoration: variantTokens.idle.textDecoration
  }, isLinkUse ? {
    border: 0,
    verticalAlign: 'inherit'
  } : {
    borderRadius: theme.borderRadius['100'],
    borderWidth: theme.borderWidth['100'],
    borderStyle: 'solid',
    verticalAlign: 'middle'
  }, {
    ':hover:not([aria-disabled="true"])': {
      backgroundColor: toColor(theme, variantTokens.hover.backgroundColor),
      borderColor: toColor(theme, variantTokens.hover.borderColor),
      color: toColor(theme, variantTokens.hover.color),
      textDecoration: variantTokens.hover.textDecoration
    },
    ':active:not([aria-disabled="true"])': {
      backgroundColor: toColor(theme, variantTokens.active.backgroundColor),
      borderColor: toColor(theme, variantTokens.active.borderColor),
      color: toColor(theme, variantTokens.active.color),
      textDecoration: variantTokens.active.textDecoration
    },
    '&[aria-disabled="true"]': {
      backgroundColor: toColor(theme, variantTokens.disabled.backgroundColor),
      borderColor: toColor(theme, variantTokens.disabled.borderColor),
      color: toColor(theme, variantTokens.disabled.color),
      cursor: 'not-allowed',
      textDecoration: 'none'
    }
  });
};
export const useSizeCssObject = sizeTokens => {
  const theme = useTheme();
  const iconSize = useIconSize(sizeTokens.iconSize);
  return {
    paddingBlockStart: sizeTokens.paddingBlockStart && theme.space[sizeTokens.paddingBlockStart],
    paddingBlockEnd: sizeTokens.paddingBlockEnd && theme.space[sizeTokens.paddingBlockEnd],
    paddingInlineStart: theme.space[sizeTokens.paddingInlineStart],
    paddingInlineEnd: theme.space[sizeTokens.paddingInlineEnd],
    width: sizeTokens.width,
    maxWidth: '100%',
    '& svg[data-icon-name]': {
      verticalAlign: 'middle',
      width: iconSize,
      blockSize: iconSize
    }
  };
};
export const useResponsiveStyles = responsive => {
  const theme = useTheme();
  return Object.assign({}, responsive && {
    [smallMediaQuery]: {
      width: '100%',
      span: {
        justifyContent: 'center'
      }
    },
    '& + &': {
      [gtSmallMediaQuery]: {
        marginInlineStart: theme.space['300']
      },
      [smallMediaQuery]: {
        marginBlockStart: theme.space['200']
      }
    }
  }, !responsive && {
    '& + &': {
      marginInlineStart: theme.space['300']
    }
  });
};