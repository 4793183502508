const sharedDisabledTokens = {
  backgroundColor: 'fill-tertiary-default',
  borderColor: 'border-primary-disabled',
  color: 'text-primary-disabled',
  cursor: 'auto'
};
export const setAsActive = useTokens => {
  useTokens.idle = useTokens.active;
  return useTokens;
};
export const getUseTokens = use => {
  const tokens = {
    primary: {
      idle: {
        backgroundColor: 'fill-brand-default',
        borderColor: 'border-brand-default',
        color: 'text-primary-on-fill-default'
      },
      hover: {
        backgroundColor: 'fill-brand-hover',
        borderColor: 'fill-brand-hover',
        color: 'text-primary-on-fill-default'
      },
      active: {
        backgroundColor: 'fill-brand-pressed',
        borderColor: 'fill-brand-pressed',
        color: 'text-primary-on-fill-default'
      },
      disabled: Object.assign({}, sharedDisabledTokens)
    },
    'primary-white': {
      idle: {
        backgroundColor: 'fill-surface-default',
        borderColor: 'transparent',
        color: 'text-primary-default'
      },
      hover: {
        backgroundColor: {
          color: 'fill-surface-default',
          alpha: 0.9
        },
        borderColor: 'transparent',
        color: 'text-primary-default'
      },
      active: {
        backgroundColor: 'fill-surface-default',
        borderColor: 'transparent',
        color: 'text-primary-default'
      },
      disabled: Object.assign({}, sharedDisabledTokens)
    },
    secondary: {
      idle: {
        backgroundColor: 'fill-surface-default',
        borderColor: 'border-brand-default',
        color: 'text-brand-default'
      },
      hover: {
        backgroundColor: 'fill-accent-light-orange-subtle',
        borderColor: 'border-brand-default',
        color: 'text-brand-default'
      },
      active: {
        backgroundColor: 'fill-primary-subtle',
        borderColor: 'border-brand-default',
        color: 'text-brand-default'
      },
      disabled: Object.assign({}, sharedDisabledTokens)
    },
    'secondary-ghost': {
      idle: {
        backgroundColor: 'transparent',
        borderColor: 'border-primary-subtle-default',
        color: 'text-primary-on-fill-default'
      },
      hover: {
        backgroundColor: 'transparent',
        borderColor: 'border-primary-subtle-default',
        color: 'text-primary-on-fill-default'
      },
      active: {
        backgroundColor: 'transparent',
        borderColor: 'border-primary-subtle-default',
        color: 'text-primary-on-fill-default'
      },
      disabled: Object.assign({}, sharedDisabledTokens)
    },
    tertiary: {
      idle: {
        backgroundColor: 'fill-accent-neutral-default',
        borderColor: 'fill-accent-neutral-default',
        color: 'text-primary-on-fill-default'
      },
      hover: {
        backgroundColor: 'fill-accent-neutral-hover',
        borderColor: 'fill-accent-neutral-hover',
        color: 'text-primary-on-fill-default'
      },
      active: {
        backgroundColor: 'fill-accent-neutral-pressed',
        borderColor: 'fill-accent-neutral-pressed',
        color: 'text-primary-on-fill-default'
      },
      disabled: Object.assign({}, sharedDisabledTokens)
    },
    'tertiary-light': {
      idle: {
        backgroundColor: 'fill-tertiary-default',
        borderColor: 'border-primary-default',
        color: 'text-primary-subtle'
      },
      hover: {
        backgroundColor: 'fill-tertiary-hover',
        borderColor: 'border-primary-default',
        color: 'text-primary-subtle'
      },
      active: {
        backgroundColor: 'fill-tertiary-pressed',
        borderColor: 'border-primary-subtle-pressed',
        color: 'text-primary-subtle'
      },
      disabled: Object.assign({}, sharedDisabledTokens)
    },
    'tertiary-extra-light': {
      idle: {
        backgroundColor: 'fill-surface-default',
        borderColor: 'border-primary-default',
        color: 'text-primary-subtle'
      },
      hover: {
        backgroundColor: 'fill-tertiary-hover',
        borderColor: 'border-primary-default',
        color: 'text-primary-subtle'
      },
      active: {
        backgroundColor: 'fill-tertiary-pressed',
        borderColor: 'border-primary-subtle-pressed',
        color: 'text-primary-subtle'
      },
      disabled: Object.assign({}, sharedDisabledTokens)
    },
    danger: {
      idle: {
        backgroundColor: 'fill-alert-default',
        borderColor: 'border-alert-default',
        color: 'text-primary-on-fill-default'
      },
      hover: {
        backgroundColor: 'fill-alert-hover',
        borderColor: 'fill-alert-hover',
        color: 'text-primary-on-fill-default'
      },
      active: {
        backgroundColor: 'fill-alert-pressed',
        borderColor: 'fill-alert-pressed',
        color: 'text-primary-on-fill-default'
      },
      disabled: Object.assign({}, sharedDisabledTokens)
    },
    transparent: {
      idle: {
        backgroundColor: 'transparent',
        borderColor: 'transparent',
        color: 'text-interactive-default'
      },
      hover: {
        backgroundColor: 'transparent',
        borderColor: 'transparent',
        color: 'text-interactive-default-hover',
        textDecoration: 'underline'
      },
      active: {
        backgroundColor: 'transparent',
        borderColor: 'transparent',
        color: 'text-interactive-default-pressed',
        textDecoration: 'underline'
      },
      disabled: Object.assign({}, sharedDisabledTokens, {
        backgroundColor: 'transparent',
        borderColor: 'transparent'
      })
    },
    unstyled: {
      idle: {
        backgroundColor: 'fill-surface-default',
        borderColor: 'border-primary-on-fill',
        color: 'text-primary-default'
      },
      hover: {
        backgroundColor: 'fill-surface-default',
        borderColor: 'border-primary-on-fill',
        color: 'text-primary-default'
      },
      active: {
        backgroundColor: 'fill-surface-default',
        borderColor: 'border-primary-on-fill',
        color: 'text-primary-default'
      },
      disabled: Object.assign({}, sharedDisabledTokens)
    },
    form: {
      idle: {
        backgroundColor: 'fill-surface-raised',
        borderColor: 'border-primary-default',
        color: 'text-primary-default'
      },
      hover: {
        backgroundColor: 'fill-surface-raised',
        borderColor: 'border-primary-default',
        color: 'text-primary-default'
      },
      active: {
        backgroundColor: 'fill-surface-raised',
        borderColor: 'border-primary-default',
        color: 'text-primary-default'
      },
      disabled: Object.assign({}, sharedDisabledTokens)
    },
    'form-on-dark': {
      idle: {
        backgroundColor: 'fill-surface-default',
        borderColor: 'border-primary-default',
        color: 'text-primary-default'
      },
      hover: {
        backgroundColor: 'fill-surface-default',
        borderColor: 'border-primary-default',
        color: 'text-primary-default'
      },
      active: {
        backgroundColor: 'fill-surface-default',
        borderColor: 'border-primary-default',
        color: 'text-primary-default'
      },
      disabled: Object.assign({}, sharedDisabledTokens)
    },
    link: {
      idle: {
        backgroundColor: 'transparent',
        borderColor: 'transparent',
        color: 'text-interactive-default'
      },
      hover: {
        backgroundColor: 'transparent',
        borderColor: 'transparent',
        color: 'text-interactive-default-hover',
        textDecoration: 'underline'
      },
      active: {
        backgroundColor: 'transparent',
        borderColor: 'transparent',
        color: 'text-interactive-default-pressed',
        textDecoration: 'underline'
      },
      disabled: Object.assign({}, sharedDisabledTokens, {
        backgroundColor: 'transparent',
        borderColor: 'transparent'
      })
    },
    'link-danger': {
      idle: {
        backgroundColor: 'transparent',
        borderColor: 'transparent',
        color: 'text-alert-default'
      },
      hover: {
        backgroundColor: 'transparent',
        borderColor: 'transparent',
        color: 'text-alert-hover',
        textDecoration: 'underline'
      },
      active: {
        backgroundColor: 'transparent',
        borderColor: 'transparent',
        color: 'text-alert-pressed',
        textDecoration: 'underline'
      },
      disabled: Object.assign({}, sharedDisabledTokens, {
        backgroundColor: 'transparent',
        borderColor: 'transparent'
      })
    },
    'link-on-dark': {
      idle: {
        backgroundColor: 'transparent',
        borderColor: 'transparent',
        color: 'text-primary-on-fill-default',
        textDecoration: 'underline'
      },
      hover: {
        backgroundColor: 'transparent',
        borderColor: 'transparent',
        color: 'text-interactive-default-pressed',
        textDecoration: 'underline'
      },
      active: {
        backgroundColor: 'transparent',
        borderColor: 'transparent',
        color: 'fill-tertiary-default',
        textDecoration: 'underline'
      },
      disabled: Object.assign({}, sharedDisabledTokens, {
        backgroundColor: 'transparent',
        borderColor: 'transparent'
      })
    },
    'link-on-bright': {
      idle: {
        backgroundColor: 'transparent',
        borderColor: 'transparent',
        color: 'text-primary-default',
        textDecoration: 'underline'
      },
      hover: {
        backgroundColor: 'transparent',
        borderColor: 'transparent',
        color: 'text-primary-default',
        textDecoration: 'underline'
      },
      active: {
        backgroundColor: 'transparent',
        borderColor: 'transparent',
        color: 'text-primary-default',
        textDecoration: 'underline'
      },
      disabled: Object.assign({}, sharedDisabledTokens, {
        backgroundColor: 'transparent',
        borderColor: 'transparent'
      })
    },
    'view-group': {
      idle: {
        backgroundColor: 'fill-surface-raised',
        borderColor: 'border-primary-default',
        color: 'text-primary-subtle'
      },
      hover: {
        backgroundColor: 'fill-surface-default',
        borderColor: 'border-primary-default',
        color: 'text-primary-subtle'
      },
      active: {
        backgroundColor: '#e5eaf0',
        borderColor: 'border-primary-default',
        color: 'text-primary-subtle'
      },
      disabled: Object.assign({}, sharedDisabledTokens)
    }
  };
  return tokens[use];
};
export const isFormUse = use => use === 'form' || use === 'form-on-dark';
export const isLinkUse = use => use === 'link' || use === 'link-danger' || use === 'link-on-dark' || use === 'link-on-bright';
export const isLinkUseAlwaysUnderlined = use => use === 'link-on-dark' || use === 'link-on-bright';

/**
 * This helper retrieves size tokens for a particular size (extra-small, small, medium).
 *
 * @param size - The current variant of the Button.
 * @param use - The current variant of the Button.
 *
 * @returns A new object containing the typography, padding, and gap tokens for a particular size.

 */
export const getSizeTokens = (size, use) => {
  // Tokens for common properties across variants
  const tokens = {
    medium: {
      gap: '200',
      paddingBlockStart: '250',
      paddingBlockEnd: '250',
      iconSize: '200',
      typography: 'heading-50',
      paddingInlineStart: '600',
      paddingInlineEnd: '600'
    },
    small: {
      gap: '200',
      paddingBlockStart: '200',
      paddingBlockEnd: '200',
      iconSize: '100',
      typography: 'detail-100',
      paddingInlineStart: '400',
      paddingInlineEnd: '400'
    },
    'extra-small': {
      gap: '100',
      paddingBlockStart: '100',
      paddingBlockEnd: '100',
      iconSize: '100',
      typography: 'detail-100',
      paddingInlineStart: '300',
      paddingInlineEnd: '300'
    }
  };

  // Specific adjustments for 'transparent' use
  const transparentAdjustments = {
    medium: {
      paddingInlineStart: '300',
      paddingInlineEnd: '300'
    },
    small: {
      paddingInlineStart: '200',
      paddingInlineEnd: '200'
    },
    'extra-small': {
      paddingInlineStart: '100',
      paddingInlineEnd: '100'
    }
  };

  // Specific adjustments for 'form' use
  const formAdjustments = {
    medium: {
      typography: 'body-200',
      paddingInlineStart: '300',
      paddingInlineEnd: '400'
    },
    small: {
      typography: 'detail-200',
      paddingInlineStart: '200',
      paddingInlineEnd: '300'
    },
    'extra-small': {
      typography: 'detail-200',
      paddingInlineStart: '100',
      paddingInlineEnd: '200'
    }
  };
  if (use === 'transparent') {
    tokens[size] = Object.assign({}, tokens[size], transparentAdjustments[size]);
  }
  if (isFormUse(use)) {
    tokens[size] = Object.assign({}, tokens[size], formAdjustments[size], {
      width: '100%'
    });
  }
  if (isLinkUse(use)) {
    tokens[size] = Object.assign({}, tokens[size], {
      paddingBlockStart: '0',
      paddingInlineEnd: '0',
      paddingBlockEnd: '0',
      paddingInlineStart: '0'
    });
  }
  return tokens[size];
};