import Raven from 'raven-js';
import PortalIdParser from 'PortalIdParser';
const projectsWithoutPortalId = ['integrated-plugin-ui'];
export const getPortalId = () => {
  var _hubspot;
  const portalId = PortalIdParser.get();

  /**
   * This is a temporary fix while the library is used in integrated-plugin-ui
   * as part of GPRO0003.
   *
   * - setup-guide-components imports useIsGPRO0003
   * - integrated-plugin-ui uses setup-guide-components but doesn't have a portalId
   */
  const currentProject = (_hubspot = window.hubspot) === null || _hubspot === void 0 || (_hubspot = _hubspot.bender) === null || _hubspot === void 0 ? void 0 : _hubspot.currentProject;
  if (!portalId && !projectsWithoutPortalId.includes(currentProject)) {
    Raven.captureMessage('PortalID not set.');
  }
  return portalId;
};