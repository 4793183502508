import Raven from 'raven-js';
import { registerQuery } from 'data-fetching-client';
import { HubUserAttributesClient } from 'frontend-preferences-client';
const hubUserAttributesClient = HubUserAttributesClient.forCaller('setup-guide-ui');
export const SET_SHOW_SEQUENCE_COACHING_TIP = registerQuery({
  fieldName: 'sequenceCoachingTooltip',
  args: [],
  fetcher: () => hubUserAttributesClient.fetch('GrowthPro-GPRO0006AudienceVariant', 'false').catch(error => {
    Raven.captureException(error);
  })
});