import PortalIdParser from 'PortalIdParser';
import userInfo from 'hub-http/userInfo';
import { INITIALIZE_AUTH } from './ActionTypes';
import { fetchOnboardingSettings } from './OnboardingActions';
import { fetchDemographics } from '../api/demographicsApi';
import { getGatesWithUrlOverrides } from '../utils/auth';
import { fetchSeatAssignments } from '../api/seatAssignmentsApi';
import { getHubInfo } from '../api/portalApi';
export const getAuth = () => async dispatch => {
  PortalIdParser.get();
  const {
    user,
    gates,
    portal
  } = await userInfo();
  const [demographics, seats, hub] = await Promise.all([fetchDemographics(), fetchSeatAssignments(), getHubInfo()]);
  dispatch({
    type: INITIALIZE_AUTH,
    user,
    gates: getGatesWithUrlOverrides(gates),
    portal,
    demographics,
    seats,
    hub
  });
  return dispatch(fetchOnboardingSettings());
};